import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'
//import { Grid } from '../../../components'
import './inputField.scss'

export default class InputField extends Component {
  render () {
    const { input, label, type, meta: { touched, error }, labelClick, content, labelPosition, disabled } = this.props
    let className = error && touched ? 'form-input validation-err' : 'form-input'
    return (
      <div>
        <label htmlFor={label} onClick={labelClick}>{label}</label>
        <Input
          className={className} {...input}
          placeholder={content}
          id={label}
          type={type}
          disabled={disabled}
          labelPosition= {labelPosition}
        />
      </div>
    )
  }
}
