/* eslint-disable no-console */
import React, { Component } from 'react'
import { connect } from "react-redux"
import { Header, Button, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './mainHeader.scss';
import imgSunny from '../../assets/img/sunny.png'
import { Auth } from 'aws-amplify';



@connect(
    ({ screenWidth }) => ({
        isMobile: screenWidth.isMobile,
    }), {

}
)


class MainHeader extends Component {

    constructor(props) {
        super(props)
        this.signOut = this.signOut.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.username !== prevProps.username) {
          this.render()
        }
      }

    signOut() {
        Auth.signOut().then(() => {
            this.props.props.onStateChange('signedOut');
            window.localStorage.clear()
            window.location = "/"
        }).catch(e => {
            console.log(e);
        });
    }

    render() {
        const { isMobile } = this.props;


        return (
            <div className="header-wrapper">
                <Header className="main-header">
                <img alt="" className="online" src={imgSunny}></img>
                    <Dropdown
                        className="username-dropdown"
                        text={isMobile ? null : this.props.username}
                        icon={isMobile ? 'user' : 'dropdown'}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="changePassword" text='Change password' />
                        </Dropdown.Menu>
                    </Dropdown>
                   


                <Button className='signout-button' onClick={this.signOut}>Sign Out</Button>
                <div className="logo"></div>
                </Header>
            </div >
        );
    }
}

export default MainHeader
