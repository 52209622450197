/* eslint-disable no-console */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/main.scss';
import aws_exports from './aws-exports';
import AppWithAuth from './AppWithAuth';
import { Auth } from 'aws-amplify';



Auth.configure({
    ...aws_exports,
    authenticationFlowType: 'USER_SRP_AUTH'
  });

ReactDOM.render(<AppWithAuth />, document.getElementById('root'))
