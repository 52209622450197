const COUNT_WIDTH = 'COUNT_WIDTH'

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false
}

export default function screenWidth (state = initialState, action = {}) {
  switch (action.type) {
    case COUNT_WIDTH:
      return {
        ...state,
        isMobile: window.matchMedia('(min-width: 350px) and (max-width: 767px)').matches,
        isTablet: window.matchMedia('(min-width: 350px) and (max-width: 767px)').matches,
        isDesktop: window.matchMedia('(min-width: 767px) and (max-width: 1280px)').matches
      }
    default:
      return state
  }
}

export const countWidth = () => {
  return {
    type: COUNT_WIDTH
  }
}
