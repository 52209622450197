export const START_SIGNIN = 'auth/SIGNIN_START'
export const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS'
export const SIGNIN_FAIL = 'auth/SIGNIN_FAIL'

export const LOG_OUT_SUCCESS = 'auth/LOG_OUT_SUCCESS'
const initialState = {
  isAuthorized: false,
  loggingIn: false,
  loginError: null
}

export default function login (state = initialState, action = {}) {
  switch (action.type) {
    case START_SIGNIN:
      return {
        ...state,
        loggingIn: true
      }
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        loggingIn: false,
        loginError: null
      }
    case SIGNIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loginError: action.error && action.error.response && action.error.response.data ? action.error.response.data.message : action.error,
        errorStatus: action.error && action.error.response && action.error.response.data ? action.error : action.error
      }
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        isAuthorized: false
      }
    default:
      return state
  }
}

export function signIn () {
  // return {
  //     types: [START_SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
  //     promise: (client) => client.post(``, data),
  // };
}
