/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { ChangePasswordForm } from "../../components";
import '../../styles/containers/searchContainer.scss';
import { } from 'semantic-ui-react';
import { Container } from '../../components';
//import '../../styles/containers/login.scss';
import { Auth } from "aws-amplify";
import { Sidebar, Segment } from 'semantic-ui-react';


class ChangePassword extends Component {


    constructor(props) {
        super(props);
    }

    submitChangePasswordForm = (values) => {
        event.preventDefault();
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, values.oldPassword, values.newPassword);
            })
            .then(data => this.props.history.push('dashboard'))
            .catch(err => window.alert(err.message));
    }

    render() {
        return (
            <Container>

                <Sidebar.Pusher>
                    <Segment basic>
                        <div>
                            <ChangePasswordForm
                                onSubmit={this.submitChangePasswordForm}
                            />
                        </div>
                    </Segment>
                </Sidebar.Pusher>



            </Container>
        );
    }
}

export default ChangePassword
