import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Input, Button, Header } from 'semantic-ui-react'
import './changePassword.scss';
import { validate } from './validation';

const renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
    <div className='input-wrapper-div'>
        <label>{label}</label>
        <Input className={className} {...input} placeholder={label} type={type} />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const ChangePasswordForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props
    return (
        <form className="form change-password-form" onSubmit={handleSubmit}>
            <Header as='h2'>Change Password</Header>
            <Field className='input-fields'
                name="oldPassword"
                type="password" component={renderField}
                label="Old Password" />
            <Field className='input-fields' name="newPassword" type="password" component={renderField}
                label="New Password" />
            <Field className='input-fields' name="confirmNewPassword"
                type="password"
                component={renderField}
                label="Confirm New Password" />
            <div>
                <Button primary type="submit" disabled={submitting}>Submit</Button>
                <Button secondary type="button" disabled={pristine || submitting} onClick={reset}>Cancel</Button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'changePassword',  // a unique identifier for this form
    validate               // <--- validation function given to redux-form
})(ChangePasswordForm)
