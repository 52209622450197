/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Component } from 'react'
import { Menu, Segment, Dropdown, Button, Icon, Input, Form, TextArea, Checkbox } from 'semantic-ui-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { connect } from 'react-redux';
import CurrentQueueOverview from './CurrentQueueOverview'
import './queuesView.scss'
import { validationEmailSubmit } from './validationEmailSubmit'
import { baseURL } from '../../helpers/apiClient'
import axios from 'axios';
import Moment from 'moment'
import 'qs'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import { saveAs } from 'FileSaver'

@connect(
    ({ screenWidth }) => ({
        isMobile: screenWidth.isMobile
    }), {
}
)

class QueuesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRangeArray: [new Date(), new Date()],
            datesRange: Moment().local().format('DD/MM/YYYY'),
            client: 'All',
            userRole: this.props.userRole,
            compressedEmailPanel: true,
            emailSubject: '',
            emailBody: '',
            dateFrom: Moment().subtract(86,"days").format('DD/MM/YYYY'),//Moment().local().format('DD/MM/YYYY'),
            dateTo: Moment().subtract(87,"days").format('DD/MM/YYYY'),// Moment().local().format('DD/MM/YYYY'),
            emailRecipients: '',
            jobStateFilterStr: 'All',
            report: '',
            attachPdf: true,
            attachCsv: '',
            clientFilterArr: [],
            optionsClients: [],
            jobStateFilterArr: [],
            tableData: [], //tableData,
            numResults: 0,
            currentPage: 1,
            totalPages: 0,
            currentPageNumResults: 0,
        }
        this.handleGetByFilters = this.handleGetByFilters.bind(this);
        this.getClients = this.getClients.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearAllFilter = this.clearAllFilter.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.cancelEmail = this.cancelEmail.bind(this)
        this.handleGetByFilters = this.handleGetByFilters.bind(this)
        this.downloadReport = this.downloadReport.bind(this)



    }


    componentDidMount() {
        this.setState({ emailSubject: 'CloudDirect job report - ' });
        var emailBodyInitialContent = "Attached is the report from CloudDirect production based on the following filters:\n\nDate from: \nDate to: \nStates: " + this.state.jobStateFilterStr;
        this.setState({ emailBody: emailBodyInitialContent });
        this.getClients()
        this.handleGetByFilters(1)
    }

    getClients = () => {

        fetch(`${baseURL}clients`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
            .then((data) => {
                let myItems = [{ value: 'All', text: 'All', sid: 'All' }];
                data.results.forEach(function (item) {
                    myItems.push({
                        value: item.sid,
                        text: item.name,
                        sid: item.sid
                    });

                });
                this.setState({
                    optionsClients: myItems
                })
            })

    }


    handleGetByFilters = (currentPage) => {
        var qs = require('qs')
        var dateRangeArr = []

        if (this.state.dateFrom !== null && this.state.dateFrom !== '') {
            dateRangeArr[0] = 'ge.' + Moment(this.state.dateFrom, "DD/MM/YYYY").utc().format('YYYY-MM-DDThh:mm:ss')
        }
        if (this.state.dateTo !== null && this.state.dateTo !== '') {
            dateRangeArr[1] = 'lt.' + Moment(this.state.dateTo, "DD/MM/YYYY").add(1, 'days').utc().format('YYYY-MM-DDThh:mm:ss')
        }
        axios.get(`${baseURL}jobs`, {
            params: {
                ...(this.state.client === 'All' ? null : { client_sid: this.state.client }),
                ...(dateRangeArr.length > 0 ? { created: dateRangeArr } : null),
                ...(this.state.jobStateFilterStr === 'All' ? null : { state: this.state.jobStateFilterStr.toLowerCase() }),
                page: currentPage,
                //  per_page: 5,
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
            headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
                'Content-Type': 'application/json',
            },
            data: {},
        }).then((res) => {
            this.setState({
                tableData: res.data.results,
                currentPageNumResults: res.data.results.length,
                currentPage: res.data._pagination.page,
                numResults: res.data._pagination.total,
                totalPages: res.data._pagination.pages,
            })
        })
    }

    downloadReport = async () => {
        try {
            var qs = require('qs')
          //  var FileSaver = require('file-saver');
         var data = [
            { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
            { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
            { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
          ];
            var dateRangeArr = []

            if (this.state.dateFrom !== null && this.state.dateFrom !== '') {
                dateRangeArr[0] = 'ge.' + Moment(this.state.dateFrom, "DD/MM/YYYY").utc().format('YYYY-MM-DDThh:mm:ss')
            }
            if (this.state.dateTo !== null && this.state.dateTo !== '') {
                dateRangeArr[1] = 'lt.' + Moment(this.state.dateTo, "DD/MM/YYYY").add(1, 'days').utc().format('YYYY-MM-DDThh:mm:ss')
            }

            var details = {
                ...(this.state.client === 'All' ? null : { client_sid: this.state.client }),
                ...(dateRangeArr.length > 0 ? { processed: dateRangeArr } : null),
                ...(this.state.jobStateFilterStr === 'All' ? null : { state: this.state.jobStateFilterStr.toLowerCase() }),
                // file_format: this.state.report,
            }



            var formBody = [];
            formBody = qs.stringify(details, { arrayFormat: 'repeat', encode: false }) //formBody.join("&");

            const response = await fetch(`${baseURL}reports`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.props.accessToken,
                },
                body: formBody
            });
            const reportPDF = await response.arrayBuffer();

            if (response.status == 200) {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [reportPDF],
                    { type: 'application/pdf' });

                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                var fileName = 'CloudDirect job report.' + this.state.report
                if (this.state.dateFrom != null) {
                    fileName = 'CloudDirect job report_' + this.state.dateFrom + '_' + this.state.dateTo
                }
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                     window.navigator.msSaveOrOpenBlob(file, fileName+".pdf");
                } else {
                    //Open the URL on new Window
                    window.open(fileURL);
                }
                if (this.state.report.includes('CSV')){
                    //  var blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                    // FileSaver.saveAs(blob, fileName+".csv");
                    this.exportToCSV(this.state.tableData, fileName)
                }
            } else {
                window.alert("Error in downloading report")
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    exportToCSV = (csvData, fileName) => {
        const fileType = 'text/csv';
        const fileExtension = '.csv';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    handleChangeCheckbox = (event, { name, checked }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: checked });
        }
    }






    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }

        if (name === 'report' && value !== 'Email' && value !== '') {
            this.setState({ compressedEmailPanel: true });
            this.setState({ report: value }, function () {
                this.downloadReport()
            });
        }

        if (name === 'report' && value === 'Email') {
            this.setState({ compressedEmailPanel: !this.state.compressedEmailPanel });
            this.setState({ emailRecipients: '' });
            this.setState({ emailSubject: 'CloudDirect job report - ' + this.state.datesRange });
            let emailBodyInitialContent = "Attached is the report from CloudDirect production based on the following filters:\n\nDate from: " + (this.state.dateFrom ? this.state.dateFrom : '') + "\nDate to: " + (this.state.dateTo ? this.state.dateTo : '') + "\nStates: " + this.state.jobStateFilterStr;
            this.setState({ emailBody: emailBodyInitialContent });
            this.setState({ attachCsv: false });
            this.setState({ attachPdf: true })
        }

        if (name === 'jobState') {
            this.setState({ jobStateFilterArr: value.length > 0 ? value : [] });
            let jobStates = value.length > 0 ? value.join(",") : 'All'
            this.setState({ jobStateFilterStr: jobStates }, function () {
                this.handleGetByFilters(1)
            });
            let emailBodyInitialContent = "Attached is the report from CloudDirect production based on the following filters:\n\nDate from: " + (this.state.dateFrom ? this.state.dateFrom : '') + "\nDate to: " + (this.state.dateTo ? this.state.dateTo : '') + "\nStates: " + jobStates;
            this.setState({ emailBody: emailBodyInitialContent });
        }

        if (name === 'client') {
                let tempValue = []
                if (value.includes('All')){
                    tempValue.push(value[value.indexOf('All')])
                } else {
                    tempValue = value
                }
            this.setState({ clientFilterArr: tempValue.length > 0 ? tempValue : [] });
            let clients = tempValue.length > 0 ? tempValue.join(",") : 'All'
            this.setState({ client: clients }, function () {
                this.handleGetByFilters(1)
            });
        }

    }

    clearAllFilter = () => {
        this.setState({ datesRange: '' });
        this.setState({ dateRangeArray: null });
        this.setState({ jobStateFilterArr: [] });
        this.setState({ jobStateFilterStr: 'All' });
        this.setState({ dateFrom: null });
        this.setState({ dateTo: null });
        this.setState({ clientFilterArr: [] });
        this.setState({ client: 'All' });
        this.setState({ emailRecipients: '' });
        this.setState({ emailSubject: 'CloudDirect job report - ' });
        var emailBodyInitialContent = "Attached is the report from CloudDirect production based on the following filters:\n\nDate from: \nDate to: \nStates: All"
        this.setState({ emailBody: emailBodyInitialContent }, function () {
            this.handleGetByFilters(1)
        });

    }

    handleDateChange = dateRangeArray => {
        this.setState({ dateRangeArray })
        let value = null
        if (dateRangeArray != null) {
            value = Moment(dateRangeArray[0]).format("DD/MM/YYYY") + " - " + Moment(dateRangeArray[1]).format("DD/MM/YYYY")
            this.setState({ datesRange: value })
            this.setState({ emailSubject: 'CloudDirect job report - ' + value });
        } else {
            this.setState({ datesRange: '' });
            this.setState({ emailSubject: 'CloudDirect job report' });
        }

        let dateFrom = value ? value.split('-')[0].trim() : null
        let dateTo = value ? value.split('-')[1].trim() : null
        this.setState({ dateFrom: dateFrom });
        this.setState({ dateTo: dateTo }, function () {
            this.handleGetByFilters(1)
        });
        let emailBodyInitialContent = "Attached is the report from CloudDirect production based on the following filters:\n\nDate from: " + (dateFrom ? dateFrom : '') + "\nDate to: " + (dateTo ? dateTo : '') + "\nStates: " + this.state.jobStateFilterStr;
        this.setState({ emailBody: emailBodyInitialContent });
    }


    sendEmail = async () => {
        let errors = validationEmailSubmit(this.state);


        if (errors && errors.emailRecipients) {
            window.alert(errors.emailRecipients)
        }

        if (errors && errors.attachPdf) {
            window.alert(errors.attachPdf)
        }

        if (errors.emailRecipients === undefined && errors.attachPdf === undefined) {
            var qs = require('qs')
            var dateRangeArr = []

            if (this.state.dateFrom !== null && this.state.dateFrom !== '') {
                dateRangeArr[0] = 'ge.' + Moment(this.state.dateFrom, "DD/MM/YYYY").utc().format('YYYY-MM-DDThh:mm:ss')
            }
            if (this.state.dateTo !== null && this.state.dateTo !== '') {
                dateRangeArr[1] = 'lt.' + Moment(this.state.dateTo, "DD/MM/YYYY").add(1, 'days').utc().format('YYYY-MM-DDThh:mm:ss')
            }

            var details = {
                ...(this.state.client === 'All' ? null : { client_sid: this.state.client }),
                ...(dateRangeArr.length > 0 ? { processed: dateRangeArr } : null),
                ...(this.state.jobStateFilterStr === 'All' ? null : { state: this.state.jobStateFilterStr.toLowerCase() }),
                email_recipients: this.state.emailRecipients,
                email_subject: this.state.emailSubject,
                email_body: JSON.stringify(this.state.emailBody),
                email_sender: this.props.userEmail,
                attach_csv: this.state.attachCsv,
                attach_pdf: this.state.attachPdf,
            }




            var formBody = [];
            formBody = qs.stringify(details, { arrayFormat: 'repeat', encode: false }) //formBody.join("&");

            const response = await fetch(`${baseURL}emailReports`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.props.accessToken,
                },
                body: formBody
            });

            try {
                const data  = await response.json();
                if (data.msg === 'success'){
                    alert("Email sent, awesome!"); 
                    this.setState({ compressedEmailPanel: !this.state.compressedEmailPanel });
                    this.setState({ report: '' });
                } else if(data.msg === 'fail'){
                    alert("Oops, something went wrong. Try again")
                } 
            } catch (error) {
                alert("Oops, something went wrong. Try again")
            } 
            
        }

    }


    cancelEmail = () => {
        this.setState({ compressedEmailPanel: !this.state.compressedEmailPanel });
        this.setState({ report: '' });
    }

    render() {
        const { isMobile } = this.props;


        const cloudDirectUserRole = (this.state.userRole === 'admin' || this.state.userRole === 'reseller' || this.state.userRole === 'staff') ? true : false
        const emailPanelClassName = this.state.compressedEmailPanel ? 'email-panel hidden' : 'email-panel open'

        const jobStateOptions = [
            { key: 'Submitted', value: 'Submitted', text: 'Submitted' },
            { key: 'Queued', value: 'Queued', text: 'Queued' },
            { key: 'Generating', value: 'Generating', text: 'Generating' },
            { key: 'Returned', value: 'Returned', text: 'Returned' },
            { key: 'Archived', value: 'Archived', text: 'Archived' },
            { key: 'Printed', value: 'Printed', text: 'Printed' },
            { key: 'Failed', value: 'Failed', text: 'Failed' },
            { key: 'Held', value: 'Held', text: 'Held' },
            { key: 'Cancelled', value: 'Cancelled', text: 'Cancelled' },
        ]

        const reportOptions = [
            { key: 'PDF', value: 'pdf', text: 'PDF' },
            { key: 'PDFCSV', value: 'PDF,CSV', text: 'PDF + CSV' },
            { key: 'Email', value: 'Email', text: 'Email' },
        ]



        return (
            <>
                <div className='menu-panel'>
                    <Menu stackable borderless fluid className='filter-panel'>
                        <Menu.Item className='filter-menu-item-1'>
                            <div> States </div>
                            <Dropdown className='state-dropdown'
                                placeholder='State'
                                name="jobState"
                                fluid
                                multiple
                                search
                                selection
                                options={jobStateOptions}
                                onChange={this.handleChange}
                                value={this.state.jobStateFilterArr}
                            />
                        </Menu.Item>

                        <Menu.Item className='filter-menu-item-2'>
                            <div> Date </div>
                            <DateRangePicker className='dateRange-dropdown'
                                calendarClassName="popup-calendar"
                                format='dd/MM/y'
                                nativeInputAriaLabel="Date"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                name="datesRange"
                                clearIcon={<Icon name='x' />}
                                calendarIcon={<Icon name='calendar outline' />}
                                placeholder="From - To"
                                value={this.state.dateRangeArray}
                                onChange={this.handleDateChange}
                                maxDate={Moment().local().toDate()}
                                minDate={Moment().local().subtract(30, 'days').toDate()}
                            />
                        </Menu.Item>

                        {cloudDirectUserRole &&
                            <Menu.Item className='filter-menu-item-3'>
                                <div> Client </div>
                                <Dropdown className='clientId-dropdown'
                                    placeholder='Client'
                                    name='client'
                                    fluid
                                    multiple
                                    search
                                    selection
                                    value={this.state.clientFilterArr}
                                    onChange={this.handleChange}
                                    options={this.state.optionsClients}
                                />
                            </Menu.Item>
                        }
                        <Menu.Item className='filter-menu-item-4'>
                            <Button onClick={this.clearAllFilter}>Clear All</Button>
                        </Menu.Item>
                        <Menu.Item className='filter-menu-item-5'>
                            <Dropdown className='report-dropdown'
                                clearable
                                placeholder='Report'
                                name='report'
                                fluid
                                selection
                                options={reportOptions}
                                value={this.state.report}
                                onChange={this.handleChange}
                            />
                        </Menu.Item>


                    </Menu>


                    <div className={emailPanelClassName}>
                        {!this.state.compressedEmailPanel &&
                            <div className='email-content'>
                                <Form name='emailForm' id='emailForm'>
                                    <div className='email-heading-wrapper'>
                                        {isMobile && <h5 className='email-heading'>New Message</h5>}
                                        {!isMobile && <h3 className='email-heading'>New Message</h3>}
                                        <Button size={isMobile ? 'mini' : 'small'} type="submit" className='send-email' onClick={this.sendEmail}>Send</Button>
                                        <Button size={isMobile ? 'mini' : 'small'} type="cancel" className='send-email' onClick={this.cancelEmail}>Cancel</Button>
                                    </div>
                                    <div className='email-files'>
                                        <Checkbox label='PDF' name='attachPdf' onChange={this.handleChangeCheckbox} checked={this.state.attachPdf} />
                                        <Checkbox label='CSV' name='attachCsv' onChange={this.handleChangeCheckbox} checked={this.state.attachCsv} />
                                    </div>
                                    <Input id='emailRecipients' name='emailRecipients' className='email-inputs' onChange={this.handleChange} placeholder='Recipients' value={this.state.emailRecipients}></Input>
                                    <Input className='email-inputs' name='emailSubject' onChange={this.handleChange} placeholder='Subject' value={this.state.emailSubject}></Input>
                                    <TextArea className='email-inputs new-line' rows={5} name='emailBody' onChange={this.handleChange} value={this.state.emailBody}>
                                    </TextArea>
                                </Form>
                            </div>
                        }

                    </div>

                </div>

                <Segment basic className='queue-panel'>
                    <CurrentQueueOverview cloudDirectUserRole={cloudDirectUserRole} accessToken={this.props.accessToken}
                        tableData={this.state.tableData}
                        handleGetByFilters={this.handleGetByFilters}
                        numResults={this.state.numResults}
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalPages}
                        currentPageNumResults={this.state.currentPageNumResults} />
                </Segment>
            </>
        )
    }
}
export default QueuesView;