import React, { Component } from 'react'
import { countWidth } from '../redux/modules/screenWidth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

@connect(
  ({screenWidth}) => ({
    isDesktop: screenWidth.isDesktop
  }), {
      countWidth
  }
)

class WrapperComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false
    }

    props.countWidth()
  }

  componentDidMount () {
    window.addEventListener('resize', this.props.countWidth)
    this.setState({ loaded: true })

    // authRequest().then(() => {
    //  this.setState({ loaded: true })
    // })
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.props.countWidth)
  }

  render () {
    return (
            <>
                {this.state.loaded && this.props.children}
            </>
    )
  }
}

export default withRouter(WrapperComponent)
