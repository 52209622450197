import React, {Component} from 'react';
import '../../styles/containers/searchContainer.scss';
import {  } from 'semantic-ui-react';


class Admin extends Component {
    render() {
        return (
            <div className="profile">
                Admin
            </div>
        );
    }
}

export default Admin
