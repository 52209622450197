/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Button, Table, Menu, Icon } from 'semantic-ui-react';
import './table.scss'
import Moment from 'moment'
import 'moment-duration-format'




class CurrentQueueOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableMinimized: false,
            tableData: this.props.tableData,// tableData,
            column: null,
            cloudDirectUserRole: this.props.cloudDirectUserRole,
            direction: 'ascending'
        };


    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.tableData) !== JSON.stringify(this.props.tableData)) {
            this.setState({
                tableData: this.props.tableData
            })
        }
    }



    sortedItems = (items, key1, direction, key2) => {
        let sortableItems = [...items];
        if (key1 !== null && key2 == null) {
            sortableItems.sort((a, b) => {
                if (a[key1] < b[key1]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[key1] > b[key1]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        } else {
            sortableItems.sort((a, b) => {
                if (a[key1][key2] < b[key1][key2]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[key1][key2] > b[key1][key2]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems
    }






    changeDateFormat = (dateStr) => {
        var date = Moment(dateStr, Moment.defaultFormat).toDate()
        return Moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss')
    }

    getElapsedTime = (fromDate, toDate) => {
        var ms = Moment(toDate, "DD/MM/YYYY HH:mm:ss").diff(Moment(fromDate, "DD/MM/YYYY HH:mm:ss"));
        return Moment.duration(ms).format('HH:mm:ss')
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    renderPagination = () => {
        const { currentPage, totalPages, numResults, currentPageNumResults } = this.props;
        const { handleGetByFilters } = this.props

        const buttons = Array(totalPages).fill({}).map((obj, index) => (
            <Menu.Item
                key={index}
                active={index + 1 === currentPage}
                disabled={index + 1 === currentPage}
                onClick={() => handleGetByFilters(index + 1)}>
                {index + 1}
            </Menu.Item>
        ));

        let lastIndex = null;
        let firstIndex = null;

        if (totalPages >= currentPage + 2) {
            lastIndex = currentPage + 2
        } else if (totalPages >= currentPage + 1) {
            lastIndex = currentPage + 1
        } else {
            lastIndex = currentPage
        }


        if (1 <= currentPage - 2) {
            firstIndex = currentPage - 2
        } else if (1 <= currentPage - 1) {
            firstIndex = currentPage - 1
        } else {
            firstIndex = 1
        }
        const firstItemInTable = currentPage > 1 ? (10 * currentPage - 10) + 1 : currentPage;
        const lastItemInTable = currentPage === totalPages ? (numResults) : (10 * currentPage);
        //  const firstItemInTable = currentPage > 1 ? (currentPageNumResults * currentPage - currentPageNumResults) : currentPage;
        //  const lastItemInTable = currentPage === totalPages ? (numResults) : (currentPageNumResults * currentPage);
        return (
            <Table.HeaderCell colSpan={this.state.cloudDirectUserRole ? '8' : '7'} className='footer-wrapper'>
                {!!numResults &&
                    <span className='footer-title'>
                        Showing {firstItemInTable} to {lastItemInTable} of {numResults} entries
                </span>
                }
                <Menu floated='right' pagination className='table-pagination'>
                    <Menu.Item disabled={currentPage <= 1} onClick={() => handleGetByFilters(currentPage - 1)} icon>
                        <Icon name='chevron left' />
                    </Menu.Item>
                    {
                        buttons.slice(firstIndex - 1, lastIndex)
                    }
                    <Menu.Item disabled={(currentPage + 1) > totalPages} onClick={() => handleGetByFilters(currentPage + 1)}
                        icon>
                        <Icon name='chevron right' />
                    </Menu.Item>
                </Menu>
            </Table.HeaderCell>
        )
    };


    handleTableSize(tableMinimizeVal) {
        this.setState({ tableMinimized: tableMinimizeVal });
    }

    oddOrEvenClassName = (index) => {
        if (index !== -1 && index % 2 === 0) {
            return "even active-cells"
        } else if (index !== -1 && index % 2 === 1) {
            return "odd active-cells"
        }
    };

    handleSort = (clickedColumn) => () => {
        const { column, tableData, direction } = this.state

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                // eslint-disable-next-line no-undef
                tableData: this.sortedItems(tableData, clickedColumn, direction, clickedColumn === 'client' ? 'sid' : null),//_.sortBy(tableData, [clickedColumn]), 
                direction: 'ascending',
            })

            return
        }

        this.setState({
            tableData: tableData.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }





    render() {
        const { column, tableData, direction } = this.state
        const { numResults } = this.props
        return (
            <div>
                <Table unstackable celled className='sheet' sortable>
                    <Table.Header className="table-header">
                        <Table.Row>
                            <Table.HeaderCell className="header-cell" colSpan={this.state.cloudDirectUserRole ? '8' : '7'}>
                                <div className='header-title'>
                                    <h2>Current Queue</h2>
                                </div>
                                <div className="header-buttons">
                                    {!this.state.tableMinimized &&
                                        <Button onClick={() => this.handleTableSize(true)} disabled={this.state.tableMinimized}
                                            icon="window minimize" />
                                    }
                                    {this.state.tableMinimized &&
                                        <Button onClick={() => this.handleTableSize(false)} disabled={!this.state.tableMinimized}
                                            icon="expand arrows alternate" />
                                    }
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>


                    </Table.Header>

                    {!this.state.tableMinimized &&
                        <>

                            <Table.Body className='scroll-block'>
                                <Table.Row className='inactive-cells' >
                                    {this.state.cloudDirectUserRole &&
                                        <Table.Cell sorted={column === 'client' ? direction : null}
                                            onClick={this.handleSort('client')}>ClientID</Table.Cell>
                                    }
                                    <Table.Cell sorted={column === 'sid' ? direction : null}
                                        onClick={this.handleSort('sid')}>JobID</Table.Cell>
                                    <Table.Cell sorted={column === 'document' ? direction : null}
                                        onClick={this.handleSort('document')}>Document</Table.Cell>
                                    <Table.Cell sorted={column === 'created' ? direction : null}
                                        onClick={this.handleSort('created')}>Start Time</Table.Cell>
                                    <Table.Cell sorted={column === 'updated' ? direction : null}
                                        onClick={this.handleSort('updated')}>Elapsed/End Time</Table.Cell>
                                    <Table.Cell sorted={column === 'state' ? direction : null}
                                        onClick={this.handleSort('state')}>State</Table.Cell>
                                    <Table.Cell sorted={column === 'priority' ? direction : null}
                                        onClick={this.handleSort('priority')}>Priority</Table.Cell>
                                    <Table.Cell sorted={column === 'document_count' ? direction : null}
                                        onClick={this.handleSort('document_count')}>Count</Table.Cell>
                                </Table.Row>


                                {tableData.map((item, index) => {
                                    return (
                                        <Table.Row key={index} className={this.oddOrEvenClassName(index)}>
                                            {this.state.cloudDirectUserRole &&
                                                <Table.Cell>{item.client.sid}</Table.Cell>
                                            }
                                            <Table.Cell>{item.sid}</Table.Cell>
                                            <Table.Cell>{item.document}</Table.Cell>
                                            <Table.Cell>{this.changeDateFormat(item.created)}</Table.Cell>
                                            {(item.state.toLowerCase() === 'generating') &&
                                                <Table.Cell>{this.getElapsedTime(this.changeDateFormat(item.created), this.changeDateFormat(item.updated))}</Table.Cell>
                                            }
                                            {(item.state.toLowerCase() !== 'generating') &&
                                                <Table.Cell>{this.changeDateFormat(item.updated)}</Table.Cell>
                                            }

                                            <Table.Cell>{this.capitalize(item.state)}</Table.Cell>
                                            <Table.Cell>{item.priority}</Table.Cell>
                                            <Table.Cell>{item.document_count}</Table.Cell>
                                        </Table.Row>
                                    )
                                })
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    {this.renderPagination()}
                                </Table.Row>
                            </Table.Footer>

                        </>
                    }
                </Table>
            </div>

        );

    }
}

export default CurrentQueueOverview