import React, { Component } from 'react';
import '../../styles/containers/searchContainer.scss';
import QueuesView from '../../components/QueuesView/QueuesView'



class Queue extends Component {


    constructor(props) {
        super(props);
    }


    render() {
        return (
            <>
                {(this.props.accessToken.length > 0) &&
                    <div className="queue-container">

                        <QueuesView accessToken={this.props.accessToken} userEmail={this.props.userEmail} userRole={this.props.userRole} />

                    </div>
                }
            </>
        );
    }
}

export default Queue
