/* eslint-disable no-console */
import React from "react";
import config from "./aws-exports";
import { Login } from "./containers/Login/Login";
import App from "./App";
import { Authenticator} from 'aws-amplify-react';

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
  
  }

  render() {
    return (
      <div className='app-container' >
        <Authenticator amplifyConfig={config} hideDefault={true}>
          <Login override={'SignIn'}/>
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;