import React, {Component} from 'react';
import './container.scss';

class Container extends Component {
    render () {
        const defaultClass = 'container';
        return(
            <div className={`${defaultClass} ${this.props.className}`}>
                    {this.props.children}
            </div>
        )
    }
}

export default Container;