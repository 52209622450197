/* eslint-disable no-console */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from '../../components';
import { Admin, ChangePassword, Dashboard, Faq, Queue } from '../../containers';
import { Auth } from 'aws-amplify';
import config from '../../aws-exports'

class MainView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            token: "",
            tokenPayload: {},
            userEmail: "",
            userRole: "",
        }
    }

    async componentDidMount() {
        const token = await Auth.currentSession()
        this.setState({ userRole: token.idToken.payload['custom:user_type']})
        this.setState({ token: token.accessToken.jwtToken })
        this.setState({ tokenPayload: token.accessToken.payload })
        this.setState({ userEmail: token.idToken.payload.email})
    }

     checkTokenExpiry(tokenPayload) {
        var tokenExpired = false
        if (Object.entries(tokenPayload).length !== 0) {

            var dateNow = new Date()
            const currentSeconds = Math.floor(dateNow.valueOf() / 1000)
            var iss = tokenPayload.iss
            var userPoolsId = iss.substring(iss.lastIndexOf("/") + 1)

            if (tokenPayload.client_id !== (`${config.aws_user_pools_web_client_id}`)) {
                tokenExpired = true
            }
            else if (userPoolsId !== (`${config.aws_user_pools_id}`)) {
                tokenExpired = true
            }
            else if (tokenPayload.token_use !== 'access') {
                tokenExpired = true
            }
            else if (currentSeconds > tokenPayload.exp) {
                tokenExpired = true
            }
        }

        if ((window.localStorage.getItem('amplify-authenticator-authState') !== "signedIn") || (this.state.userEmail.length > 0 && (this.state.userEmail !== window.localStorage.getItem('username')))){
            tokenExpired = true
        }
        

        return tokenExpired

    }


    render() {
        const isExpired = this.checkTokenExpiry(this.state.tokenPayload)
        if (!isExpired) {
            return (
                <Layout authData={this.props.authData} props={this.props.props} >
                    <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                    <Route exact path='/dashboard' render={(props) => <Dashboard {...props} accessToken={this.state.token} userRole={this.state.userRole} />}/>
                    <Route exact path='/queue'  render={(props) => <Queue {...props} accessToken={this.state.token} userEmail={this.state.userEmail} userRole={this.state.userRole} />}/>
                    <Route exact path='/faq' render={(props) => <Faq {...props} accessToken={this.state.token} userEmail={this.state.userEmail}/>}/>
                    <Route exact path='/admin' component={Admin} />
                    <Route exact path='/changePassword' component={ChangePassword} />
                </Layout>
            );
        } else {
            Auth.signOut().then(() => {
                this.props.props.onStateChange('signedOut');
                window.localStorage.clear()
                window.location = "/"
            }).catch(e => {
                console.log(e);
            });
            return null;
        }
    }
}

export default withRouter(MainView);