/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import BarChartContainer from '../BarChart/BarChartContainer'
import LineChartContainer from '../LineChart/LineChartContainer'
import { Dropdown } from 'semantic-ui-react';
import './dashboardView.scss'
import { connect } from "react-redux"
import { baseURL } from '../../helpers/apiClient'
import 'qs'
import axios from 'axios'
import Moment from 'moment'
import loader from '../../assets/img/loader.gif'


@connect(
    ({ screenWidth }) => ({
        isMobile: screenWidth.isMobile,
    }), {

}
)

class DashboardView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRole: this.props.userRole,
            client: 'All',
            optionsClients: [],
            chartData: [],
            summaryData: [],
            loading: true,
        }
        this.getSummary = this.getSummary.bind(this);
        this.getClients = this.getClients.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSummaryTask = this.getSummaryTask.bind(this);
    }

    componentDidMount() {
        this.intervalId = null
        this.getClients()
        this.getSummary()
    }

    componentWillUnmount() {
        this.setState({loading : false})
        clearInterval(this.intervalId);
    }

    getClients = () => {

        fetch(`${baseURL}clients`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
            .then((data) => {
                let myItems = [{ value: 'All', text: 'All', sid: 'All' }];
                data.results.forEach(function (item) {
                    myItems.push({
                        value: item.sid,
                        text: item.name,
                        sid: item.sid
                    });

                });
                this.setState({
                    optionsClients: myItems
                })
            })

    }

   /* getSummary = async () => {
        const response = await fetch(`${baseURL}/summary?processed=ge.2020-05-22&processed=lt.2020-06-01`, {
            headers: {
                Authorization: 'Bearer ' + this.props.accessToken
            }
        });
        const summary = await response.json();
        console.log(summary);
    }*/



    getSummary = () => {
        var qs = require('qs')

        axios.get(`${baseURL}summary`, {
            params: {
                ...(this.state.client === 'All' ? null : { client_sid: this.state.client }),
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' , encode: false }),
            headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            this.intervalId = setInterval(() => this.getSummaryTask(response.data.task), 3000)
        })
    }


    getSummaryTask = (taskId) => {
        axios.get(`${baseURL}tasks/`+taskId, {
            headers: {
                'Authorization': 'Bearer ' + this.props.accessToken,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (res.data.result.state === "completed"){
                clearInterval(this.intervalId);
                let chartData = [];
                res.data.result.data.results.reverse().forEach(function (item) {
                chartData.push({
                    name: Moment(item.name, "YYYY-MM").format("MMM"),
                    Documents: item.document_count,
                    Views: item.view_count,
                });

            });
            this.setState({
                loading: false,
                chartData: chartData,
                summaryData: res.data.result.data.summary,
            })
            }
            
        })
    }


    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({loading: true})
            this.setState({ [name]: value }, function () {
                this.getSummary()
            });
        }
    }
    render() {

        const cloudDirectUserRole = (this.state.userRole === 'admin' || this.state.userRole === 'reseller' || this.state.userRole === 'staff') ? true : false


        return (
            <>

                

                {cloudDirectUserRole &&

                    <div className='dropdown-search'>
                        <label htmlFor='client' className='dropdown-label' >Client</label>
                        <Dropdown className='dropdownFilter'
                            id='client'
                            name='client'
                            fluid
                            search
                            selection
                            value={this.state.client}
                            onChange={this.handleChange}
                            options={this.state.optionsClients}
                        />
                    </div>

                }
                <div className="summaryContainer">
                    <h2 className="summaryDetailsHeading">{cloudDirectUserRole ? 'Summary' : 'Company Summary'}</h2>
                    <div><div className="summaryDetails">{cloudDirectUserRole ? 'Clients: ' : 'Jobs Processed: '} </div><div className='summaryDetailsValue'>{this.state.summaryData.client_count}</div> </div>
                    <div><div className="summaryDetails">{cloudDirectUserRole ? 'Archives: ' : 'Documents Generated: '}</div><div className='summaryDetailsValue'>{this.state.summaryData.archive_count}</div> </div>
                    <div><div className="summaryDetails">{cloudDirectUserRole ? 'Documents: ' : 'Documents Stored: '}</div><div className='summaryDetailsValue'>{this.state.summaryData.document_count}</div> </div>
                    <div><div className="summaryDetails">Views / Retrievals: </div><div className='summaryDetailsValue'>{this.state.summaryData.view_count}</div> </div>
                    {this.state.loading &&
                    <div className="spinner"><img className="spinnerImg" src={loader} alt="" /></div>
                    }
                </div>

               
                   
                

                <div className="chartContainer">
                    <BarChartContainer chartData={this.state.chartData}/>
                    
                    <LineChartContainer chartData={this.state.chartData}/>
                </div>

               


            </>

        );
    }

}

export default DashboardView

