import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import { Provider } from 'react-redux'
import { WrapperComponent } from './containers'
import { MainView } from './View'

import store from './redux/create'

class App extends Component {
  constructor(props, context) {
    super(props, context);
  }
  
  render() {
    if (this.props.authState === "signedIn") {
      return (
        <Provider store={store}>
          <Router >
            <WrapperComponent>
              <div className="main-wrapper"> 
                <Switch>
                <Route path='/' render={(props) => <MainView {...props} props={this.props} authData={this.props.authData} authState={this.props.authState} />} />
                </Switch>
              </div>
            </WrapperComponent>
          </Router>
        </Provider>
      );
    } else {
      return null;
    }
  }
}

export default App;