import React, {Component} from 'react';
import '../../styles/containers/searchContainer.scss';
import FaqView from '../../components/FaqView/FaqView'


class Faq extends Component {
    render() {
        return (
            <div className="faq">
                <FaqView accessToken={this.props.accessToken} userEmail={this.props.userEmail}/>
            </div>
        );
    }
}

export default Faq
