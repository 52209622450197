import React, { Component } from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line
} from "recharts";
import './lineChart.scss'


class LineChartContainer extends Component {

  constructor(props) {
    super(props);
  }
 
  render() {
    const { chartData } = this.props
    return (
      <div className="lineChartContainer">
        <>
          <LineChart
            width={400}
            height={400}
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Documents" stroke="#8884d8" />
            <Line type="monotone" dataKey="Views" stroke="#82ca9d" />
          </LineChart>
        </>
      </div>
    );
  }
}
export default LineChartContainer