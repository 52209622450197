import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from "recharts";
import './barChart.scss'

class BarChartContainer extends Component {

  constructor(props) {
    super(props);
  }
 

  render() {
    const { chartData } = this.props
    return (
      <div className="barChartContainer">
        <>
          <BarChart
            width={400}
            height={400}
            data={chartData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="Documents" stackId="a" fill="#8884d8" />
            <Bar dataKey="Views" stackId="b" fill="#82ca9d" />
          </BarChart>
        </>
      </div>
    );
  }
}
export default BarChartContainer