export const validate = values => {
    const errors = {};
    if (!values.oldPassword) {
        errors.oldPassword = 'Required'
    }
    if (!values.newPassword) {
        errors.newPassword = 'Required'
    }
    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = 'Required'
    }
    if (values.newPassword && values.confirmNewPassword && values.newPassword.toString() !== values.confirmNewPassword.toString()) {
        errors.confirmNewPassword = 'The passwords donot match'
    }
    if (values.newPassword && values.oldPassword && values.oldPassword.toString() === values.newPassword.toString()) {
        errors.newPassword = 'The old and new passwords cannot be same'
    }
    return errors
};