import React, { Component } from 'react'
import { Sidebar, Menu, Segment, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import './mainSidebar.scss'
import menuItems from '../../constants/mainSidebarMenuItems'
import { connect } from 'react-redux';

@connect( 
    ({ screenWidth }) => ({
        isMobile: screenWidth.isMobile
    }), {
    }
)

class MainSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compressed: false,
        }
    }

    handleCompressSidebar = () => { 
        this.setState({ compressed: !this.state.compressed })
    };

    render() {
        const { compressed } = this.state;
        const { location, isMobile } = this.props;
        const menuItemClassName = compressed ? 'sidebar-compressed' : 'sidebar-full-width';
        const sidebarMenu = compressed ? 'sidebar-menu-compressed very thin' : 'sidebar-menu-full-width thin';
        const dashboardIconClassName = compressed ? 'dashboard-icon-compressed' : 'dashboard-icon';
        const IconClassName = compressed ? 'icon-name-compressed' : 'icon-name';
        const pusherContent = compressed ? 'pusher-content-compressed' : 'pusher-content';

        return (
            <Sidebar.Pushable className="sidebar-main" as='div'>
                <Sidebar
                    as={Menu}
                    vertical
                    visible={true}
                    width={compressed || isMobile ? 'very thin' : 'thin'}
                    className={`sidebar-menu ${sidebarMenu}`}
                    id="sidebar-menu"
                >
                    {
                        menuItems.map((item, key) => {
                            return (
                                <>
                                { ((item.name !== 'Admin') || (item.name === 'Admin' && this.props.userRole === 'admin')) &&
                                <Menu.Item
                                    key={key}
                                    as={Link}
                                    to={item.location}
                                    className={`${menuItemClassName} sidebar-mobile`}
                                    active={location.pathname === item.location}
                                >
                                    <div>
                                        <Icon name={item.iconName}
                                            className={`${dashboardIconClassName} dashboard-icon-mobile`} />
                                        <p className={IconClassName}>{item.name}</p>
                                    </div>
                                </Menu.Item>
                                }
                                </>
                            )
                        })
                    }

                    <Button
                        className='compress-button'
                        onClick={() => this.handleCompressSidebar()}
                        icon={compressed ? 'arrow alternate circle right outline' : 'arrow alternate circle left outline'}
                    />
                </Sidebar>

                <Sidebar.Pusher className={pusherContent}>
                    <Segment basic className='segment-wrapper'>
                        <div>{this.props.children}</div>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        )
    }
}
export default MainSidebar;