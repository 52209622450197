/* eslint-disable no-unused-vars */
import React from "react";
import { SignIn } from "aws-amplify-react";
import { Container } from '../../components'
import { Segment, Checkbox, Input, Button } from 'semantic-ui-react'
import CloudDirectLogo from '../../assets/img/CloudDirectLogo.png';
import lock from '../../assets/img/lock.png'
import './login.scss'

export class Login extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.loginSubmit = this.loginSubmit.bind(this)
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.state = {
      username: localStorage.username || '',
      password: localStorage.password || '',
      checkedValue: true,
      blurClassName: ''
    }
    this.inputs = {}
  }

  handleChange = (event) => {
    const input = event.target;
    this.setState({ [input.name]: input.value });
  };

  onChangeCheckbox = (event) => {
    this.setState((prevState) => ({ checkedValue: !prevState.checkedValue }))
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.setState({ blurClassName: 'blur-button' })
      this.loginSubmit()
    }
  }

  async loginSubmit() {
  
    const { checkedValue } = this.state
    if (checkedValue) {
      localStorage.username = this.state.username
      localStorage.password = this.state.password
      localStorage.checkedValue = checkedValue
    }
    if (!checkedValue) {
      localStorage.username=''
      localStorage.password=''
      localStorage.checkedValue = checkedValue
    }
    this.inputs = {
      username: this.state.username,
      password: this.state.password
    }
    await super.signIn().then(() => {
      this.setState({ blurClassName: '' })
    }).catch(e => {
      this.setState({ blurClassName: '' })
  });
    
    
  }


  showComponent(theme) {
    return (
      <Container className='login-container'>
      <div className='login-wrapper'>
       <img src={CloudDirectLogo} className="online" alt="" />
       <img src={lock} className="lock-icon" alt="" />
       <form className="login-form">
         <Segment className="form-segment padding-none">
           <div className="logo-mobile">
             <Segment className="logo">Logo</Segment>
           </div>
           <div className="border-mobile">
             <Input id='username' placeholder='Email Address' type='text' name='username' className='input-field' 
             value={this.state.username} onChange={this.handleChange}></Input>
            
             <Input id='password' placeholder='Password' type='password' name='password' className='input-field' 
              onKeyDown={this.handleKeyPress} value={this.state.password} onChange={this.handleChange}></Input>
             
             <Checkbox className='remember-checkbox' label='Remember me' checked={this.state.checkedValue} name='checkedValue' onChange={this.onChangeCheckbox} />

             <div  className={this.state.blurClassName}>
             <Button
              className="signin-button" primary
              type="button"
              onClick={this.loginSubmit}
            >
              Sign me in
            </Button>
              
             </div>
           </div>


         </Segment>
       </form>
     </div>
   </Container>
 )
  }
}