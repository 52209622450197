const menuItems = [
    {
        location: '/dashboard',
        linkTo: 'dashboard',
        iconName: 'grid layout',
        name: 'Dashboard',
    },
    {
        location: '/queue',
        linkTo: 'queue',
        iconName: 'search',
        name: 'Queue',
    },
    {
        location: '/faq',
        linkTo: 'faq',
        iconName: 'question circle outline',
        name: `FAQ's`
    },
    {
        location: '/admin',
        linkTo: 'admin',
        iconName: 'setting',
        name: `Admin`
    },
];

export default menuItems;