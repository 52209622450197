import axios from 'axios';
import {url} from '../config';

axios.defaults.headers.common = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

export const baseURL = `${url.protocol}://${url.host}`;

export const apiClient = axios.create({
    baseURL,
});

export function setToken(header) {
    apiClient.defaults.headers.Authorization = `Bearer ${header}`;
    localStorage.setItem('authHeader', header)
}

export default apiClient;
