import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import login from './login'
import screenWidth from './screenWidth'
const reducer = combineReducers({
  form: formReducer,
  login,
  screenWidth
})

export default reducer
