/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Accordion, Icon, TextArea, Button } from 'semantic-ui-react'
import './faq.scss'
import { baseURL } from '../../helpers/apiClient'



class FaqView extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: null,
          faqQuestion: '' }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
      }

    
    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    submitHandler = async (e) => {
      e.preventDefault();
            var details = {
                email_subject: 'Enquiry on CloudDirect',
                email_body: this.state.faqQuestion,
                email_sender: this.props.userEmail,
            }


            const response = await fetch(`${baseURL}contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.accessToken,
                },
                body: JSON.stringify(details)
            });

            try {
                const data  = await response.json();
                if (data.ResponseMetadata.HTTPStatusCode == 200){
                    alert("Email sent!"); 
                    this.setState({ faqQuestion: '' })
                } else if(data.status === 'error'){
                   // console.log(data.message)
                    alert("Oops, something went wrong. Try again")
                } 
            } catch (error) {
               // console.log(error)
                alert("Oops, something went wrong. Try again")
            } 
            
        

    
    }
   
    render() {
        const { activeIndex } = this.state
       
        return (

          <>
            <h3 className='faq-title'>Frequently asked questions</h3>
            <Accordion className='faq-container' styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              Can the we change or modify the jobs in the queue?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
              The short answer is no. The slightly longer answer is that the data you are seeing is up-to-the-minute log information, and the portal is informational in nature.
              </p>
            </Accordion.Content>
    
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              I can’t see my job, where is it?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
              Try adjusting the filters at the top of the queue page. Some of the filter settings may have excluded the job you are trying to locate. If the list of jobs is long then your job may be further down the list on another page of the queue table. If you still can’t locate your job after trying these suggestion please contact support.
              </p>
            </Accordion.Content>
    
            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              How can I change the content of the report?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
          <p>
          The content contained in the report is driven by the selection of the filters. Once you have the desired list of jobs in the queue table you can produce a report based on those results.
          </p>
        </Accordion.Content>

        <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              A colleague wants access to the Portal. Can this be arranged?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
          <p>
          Sure thing. Just contact your admin and they will be able to assist your colleague to get access to the Portal.
          </p>
        </Accordion.Content>
      </Accordion>
         
      <div>
      <br></br> 
        <form className='faq-form' onSubmit={this.submitHandler}>
        <div> Not covered here? Ask away... </div>
        <TextArea className='faq-inputs new-line' rows={5} name='faqQuestion' onChange={this.handleChange} value={this.state.faqQuestion}>
        </TextArea>
        <Button className='faq-submit' type="submit">Submit</Button>
        </form>
      </div>
            </>
        );
    }

}

export default FaqView

