/* eslint-disable no-console */
import React, { Component } from 'react';
import './layout.scss';
import { MainHeader, MainSidebar } from '../'
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';

class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            info: {},
            accessToken: "",
            userRole: '',
          }
    }
    
      async componentDidMount() {
        const info = await Auth.currentUserInfo()
        const token =  await Auth.currentSession()
        this.setState ({ userRole: info.attributes['custom:user_type']})
        this.setState({ info })
        this.setState({ accessToken: token.accessToken.jwtToken })
      }

      


    render() {
        const { children, location } = this.props;
        let username = ''
        if (this.state.info.attributes){
            username =  this.state.info.attributes.email
        }
        return (
            
            <div className="layout-wrapper">
                <MainHeader props={this.props.props}
                    username={ username }
                
                >
                </MainHeader>
                <MainSidebar userRole={this.state.userRole}
                    location={location}
                >
                    {children}
                </MainSidebar>
                
            </div>
            
        );
    }
}

export default withRouter(Layout)


export function updateStateChange (state, key, value) {
    const newState = { ...state, [key]: value, type: [null, null, null], }
    return newState
}



