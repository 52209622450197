import React, { Component } from 'react'
import './grid.scss'
class Grid extends Component {
  render () {
    const defaultClass = 'grid';
    return (
      <div className={`${defaultClass} ${this.props.className}`}>
        {this.props.children}
      </div>
    )
  }
}

export default Grid
