import React, {Component} from 'react';
import '../../styles/containers/searchContainer.scss';
import {  } from 'semantic-ui-react';
import DashboardView from '../../components/DashboardView/DashboardView'
import './dashboard.scss'


class Dashboard extends Component {

    constructor(props) {
        super(props);
    }
      
    render() {
        return (
            <>
            {(this.props.accessToken.length > 0) &&
            <div>
                <DashboardView accessToken={this.props.accessToken} userRole={this.props.userRole}  />
            </div>
            }
            </>
        );
    }
}

export default Dashboard
