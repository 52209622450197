export const validationEmailSubmit = values => {
    const errors = {};
    if (!values.emailRecipients) {
        errors.emailRecipients = 'Email address is required'
    } else if (values.emailRecipients) {
        const recipientArr = values.emailRecipients.split(",");
        recipientArr.map((str, index) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(recipientArr[index].trim())) {
                errors.emailRecipients = 'Invalid email address'
            }
        })
    } 
    
    if (!values.attachPdf && !values.attachCsv){
        errors.attachPdf = 'Please select at least one report format to send'
    }
    return errors
};