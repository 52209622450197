import { createStore as _createStore, applyMiddleware } from 'redux'
import clientMiddleware from './middleware/clientMiddleware'
import apiClient from '../helpers/apiClient'
import reducer from './modules/reducer'

const middleware = [clientMiddleware(apiClient)]

let finalCreateStore = applyMiddleware(...middleware)(_createStore)

const store = finalCreateStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
